.accordion {
  margin: 4.5rem 0;
  @media screen and (max-width: 640px) {
    margin: 2rem 0;
  }
}
.question {
  border-radius: 15px;
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 12px 12px 0px rgba(40, 47, 60, 0.03);
  padding: 4rem 6rem;
  position: relative;
  @media screen and (max-width: 640px) {
    padding: 2rem;
  }
  span {
    display: inline-block;
  }
  .number {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: var(--mainColor);
    margin-right: 3rem;
    font-size: 2.4rem;
    @media screen and (max-width: 640px) {
      font-size: 1.8rem;
      margin-right: 0;
    }
  }
  .title {
    font-weight: bold;
    font-size: 1.8rem;
    @media screen and (max-width: 640px) {
      font-size: 1.4rem;
      padding-right: 1rem;
    }
  }
  svg {
    position: absolute;
    right: 6rem;
    top: 50%;
    transform: translateY(-50%);
    height: 2rem;
    @media screen and (max-width: 640px) {
      right: 1rem;
      height: 1rem;
    }
  }
}
.questionWrap {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.isOpen {
  svg {
    transform: translateY(-50%) rotate(90deg);
  }
}
.answer {
  margin: 4rem;
  font-size: 1.8rem;
  line-height: 1.8;
  @media screen and (max-width: 640px) {
    margin: 2rem 1.5rem;
    font-size: 1.4rem;
  }
}
