.heading {
  font-size: 3.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4rem;
}

.heading__ja {
  color: var(--mainColor);
  font-weight: bold;
}

.heading__en {
  display: block;
  font-size: 1.4rem;
  /* margin-bottom: .5rem; */
  text-transform: uppercase;
  color: var(--subColor);
}

.text-red {
  color: var(--subColor);
}

.text-yellow {
  color: #DBFF00;
}

.label {
  background: var(--mainColor);
  color: #fff;
  display: inline-block;
  padding: .5rem 2rem;
  border-radius: 9999px;
  font-size: 1.4rem;
}



@media screen and (max-width: 640px) {
  .heading {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }

  .heading__en {
    font-size: 1.4rem;
  }
}

/* スマートフォン向けスタイル（767px以下） */
@media screen and (max-width: 767px) {
  .isPc {
    display: none;
  }

  .isPcTab {
    display: none;
  }

  .isTab {
    display: none;
  }
}

/* タブレット向けスタイル（768px - 1024px） */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .isPc {
    display: none;
  }

  .isSp {
    display: none;
  }
}

/* PC向けスタイル（1025px以上） */
@media screen and (min-width: 1025px) {
  .isTab {
    display: none;
  }

  .isTabSp {
    display: none;
  }

  .isSp {
    display: none;
  }
}