.mv {
  display: flex;
  background: linear-gradient(180deg, #77d5ff 0%, #00aaf6 100%);
  align-items: center;
  justify-content: center;
  height: 35rem;
  @media screen and (max-width: 1024px) {
    height: 30rem;
  }
  @media screen and (max-width: 767px) {
    height: 20rem;
    padding-top: 4rem;
  }
}
.title {
  font-size: 3.4rem;
  font-weight: bold;
  color: #fff;
  @media screen and (max-width: 767px) {
    font-size: 2.4rem;
  }
}
