.section {
  margin: 8rem 0 12rem;
  @media screen and (max-width: 640px) {
    margin: 6rem 0 8rem;
  }
}

.swiperBox {
  background-color: #ffffff; /* 白背景 */
  border-radius: 15px; /* radius 15pxほど */
  padding: 2.5rem 1.5rem; /* 上下2rem 左右1remのpadding */
  height: auto !important;
  border: 1px solid #eee;
  position: relative;
}
.boxLabel {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--mainColor);
  color: #fff;
  padding: 0.5rem 2rem;
  font-size: 1.4rem;
  border-radius: 15px 0 15px 0;
}

.boxTitle {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
}

.boxDescription {
  line-height: 1.8;
  font-size: 1.4rem;
}

@media screen and (max-width: 640px) {
  .boxTitle {
    font-size: 16px;
  }
  .boxDescription {
    font-size: 14px;
  }
}
