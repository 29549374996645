.priceSection {
  background: #e1f5fe;
  padding: 8rem 0;
  margin-top: -15rem;
  padding: calc(15rem + 8rem) 0 8rem;
  @media screen and (max-width: 640px) {
    margin-top: -23rem;
    padding: calc(23rem + 6rem) 0 8rem;
  }
}

.priceItem {
  background: #fff;
  border-radius: 10px;
  height: 100%;
}
.priceHeading {
  background: var(--mainColor);
  border-radius: 10px 10px 0 0;
  color: #fff;
  text-align: center;
  padding: 1.5rem;
  font-weight: 700;
  span {
    display: block;
  }
  &Sub {
    font-size: 1.4rem;
  }
  &Main {
    font-size: 2rem;
    margin-top: 1rem;
  }
  &.--orange {
    background: var(--subColor);
  }
  @media screen and (max-width: 767px) {
    padding: 1rem;
    &Sub {
      font-size: 1.2rem;
    }
    &Main {
      font-size: 1.6rem;
    }
  }
}
.recommend {
  background: #ffa500;
}
.priceContents {
  padding: 1.5rem;
}
.priceLabel {
  color: #999;
  font-weight: bold;
  text-align: center;
  font-size: 1.4rem;
}
.price {
  text-align: center;
  margin: 1.5rem 0;
  font-weight: bold;
  font-size: 2rem;
  span {
    font-size: 3.8rem;
  }
}
.priceFunction {
  &Heading {
    text-align: center;
    color: var(--mainColor);
    font-weight: bold;
    position: relative;
    font-size: 1.6rem;
    padding: 0 3%;
    margin-bottom: 2rem;
    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 30%;
      background: var(--mainColor);
      height: 2px;
    }
    &::after {
      left: 0;
    }
    &::before {
      right: 0;
    }
  }
  &List {
    li {
      padding-left: 2rem;
      position: relative;
      margin: 1rem 0;
      font-size: 1.4rem;
      font-weight: 600;
      &::before {
        content: "";
        background: url("../images/ico_check.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        vertical-align: middle;
        display: inline-block;
        margin-right: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .price {
    margin: 1rem 0;
    font-size: 1.8rem;
  }
  .priceLabel {
    font-size: 1.2rem;
  }
  .priceContents li {
    font-size: 1.3rem;
  }
}
