.footer {
  background: #f9f9f9;
  padding: 4rem 0;
  &Navi {
    display: flex;
    gap: 3rem;
    font-size: 1.4rem;
  }
  &Contents {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 640px) {
    padding: 2rem 0;
    &Navi {
      flex-wrap: wrap;
      gap: 2rem;
    }
    &Contents {
      flex-wrap: wrap;
      gap: 2rem;
    }
  }
}
.logo {
  font-size: 3.2rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: var(--mainColor);
}
.hr {
  background: #4fc3f7;
  height: 2px;
}
.copyright {
  color: #666;
  margin-top: 3rem;
}

@media screen and (max-width: 1024px) {
  .footer {
    &Contents {
      gap: 3rem;
    }
    &Navi {
      font-size: 1.4rem;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
  }
  .logo {
    font-size: 2.6rem;
    width: 20%;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    &Navi {
      font-size: 1.2rem;
    }
  }
  .copyright {
    text-align: center;
    margin-top: 2rem;
  }
}
