.hamburgerMenu {
  display: none;
  @media screen and (max-width: 1024px) {
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 6px;
    width: 40px;
    height: 40px;
    border-radius: 9999px;
    z-index: 100;
    span {
      display: inline-block;
      position: relative;
      height: 2px;
      width: 80%;
      border-radius: 999px;
      background: var(--mainColor);
      &:nth-of-type(2) {
        width: calc(80% - 10px);
      }
      &:nth-of-type(3) {
        width: calc(80% - 20px);
      }
    }
  }
  &.isActive {
    span {
      background: #fff;
      width: 80%;
      &:nth-of-type(1) {
        transform: rotate(45deg);
        top: 7px;
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: rotate(-45deg);
        bottom: 9px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .hmbBtn {
    display: none;
  }
}
.hmbBtn {
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
}
