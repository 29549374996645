.header {
  height: 8rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 1rem 4rem;
  width: 100%;
  margin-bottom: -8rem;
  transition: 0.3s all;
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 1rem 2rem;
    height: 6rem;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.header.isShow {
  @media screen and (max-width: 1024px) {
    padding: 1rem 1.5rem;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    background: #fff;
    height: auto;
    .logo svg {
      fill: var(--mainColor);
    }
  }
}
.header.isActive {
  .logo svg {
    fill: #fff;
  }
}
.headerInner {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
}

.logo {
  position: relative;
  z-index: 30;
}
.logo svg {
  fill: #fff;
  max-width: 8rem;
}
