// .form {
//   width: 750px;
//   max-width: 100%;
//   padding: 0 12px;
//   margin: 0 auto;
// }
.form label {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  font-weight: bold;
}

.form input,
.form textarea {
  display: block;
  width: 100%;
  padding: 14px 17px;
  min-height: 40px;
  border-radius: 5px;
  font-size: 1.4rem;
  outline: none;
  border: 1px solid var(--mainColor);
  transition: 0.35s all;
}

.form input:focus,
.form textarea:focus {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 17px;
}

.form textarea {
  height: 300px;
}

.row {
  margin: 2em 0;
  &:first-child {
    margin-top: 0;
  }
}

.button {
  border-radius: 9999px;
  width: 320px;
  margin: 0 auto;
  background: var(--mainColor);
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 14px 28px;
  font-size: 16px;
  letter-spacing: 0.03em;
  display: block;
  max-width: 100%;
  appearance: none;
  transition: all 0.35s;
}

.button:hover {
  background: #fff;
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
}

.required {
  display: inline-block;
  background: red;
  color: #fff;
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 2px;
  line-height: 1;
  margin-left: 10px;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .form input {
    min-height: 30px;
    padding: 10px;
  }

  .form textarea {
    height: 150px;
  }

  .form .button {
    padding: 14px 28px;
    font-size: 16px;
  }
}
