.pointInner {
  background: #e1f5fe;
  width: 125rem;
  max-width: 95%;
  margin-left: auto;
  padding: 8rem 12rem 4rem 6rem;
  border-radius: 30px 0 0 30px;
  margin-top: 10rem;
  position: relative;
}

.pointInner:nth-of-type(2n) {
  margin-right: auto;
  margin-left: 0;
  border-radius: 0 30px 30px 0;
  padding: 8rem 6rem 4rem 12rem;
}

.pointInner:nth-of-type(2n) .pointItem {
  flex-direction: row-reverse;
}

.pointItem {
  display: flex;
  gap: 7rem;
}

.pointImg {
  width: 50%;
}

.pointNumber {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  position: absolute;
  left: 5rem;
  top: -7rem;
  font-size: 4.6rem;
  color: var(--mainColor);
}

.pointInner:nth-of-type(2n) .pointNumber {
  left: auto;
  right: 5rem;
}

.pointNumber span {
  font-size: 1.8em;
  display: inline-block;
  margin: 0 1.5rem;
  letter-spacing: -0.03em;
}

.pointContent {
  width: 70%;
}

.pointContent__heading {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 3rem;
  letter-spacing: 0.05em;
}

.pointContent__headingAccent {
  display: inline-block;
  margin: 0 2rem;
}

.pointContent__headingAccent .text-number {
  font-size: 2em;
}

.pointContent__text {
  line-height: 1.8;
  font-weight: normal;
}

@media screen and (max-width: 640px) {
  .pointInner {
    padding: 6rem 3rem 4rem;
    margin-top: 6rem;
  }

  .pointInner:nth-of-type(2n) {
    padding: 6rem 3rem 4rem;
  }

  .pointItem {
    flex-wrap: wrap;
  }

  .pointImg {
    width: 100%;
  }

  .pointNumber {
    font-size: 3rem;
    top: -4rem;
    left: 4rem;

    span {
      font-size: 5rem;
    }
  }

  .pointInner:nth-of-type(2n) .pointNumber {
    right: auto;
    left: 3rem;
  }

  .pointContent {
    width: 100%;

    &__heading {
      font-size: 2.4rem;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .pointContent__heading {
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .pointItem {
    gap: 3rem;
  }
  .pointContent__heading {
    font-size: 2rem;
  }
}
