.section {
  background: #e1f5fe;
  padding-bottom: 8rem;
  @media screen and (max-width: 640px) {
    padding-bottom: 6rem;
  }
}

.text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-top: 6rem;
  @media screen and (max-width: 640px) {
    font-size: 16px;
    margin-top: 2rem;
  }
}

.ServiceItem {
  background: #fff;
  border-radius: 15px;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 100%;
  box-shadow: 0 12px 12px rgba($color: #282f3c, $alpha: 0.03);
  &__Heading {
    color: var(--mainColor);
    font-weight: bold;
    font-size: 2.2rem;
    text-align: center;
    margin-top: 1rem;
  }
  @media screen and (max-width: 1024px) {
    &__Heading {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 640px) {
    padding: 1.5rem;
    gap: 0.5rem;
    &__Heading {
      font-size: 14px;
      margin-top: 0.5rem;
    }
    &__Img {
      max-height: 4.5rem;
      img {
        height: 100%;
      }
    }
  }
}
