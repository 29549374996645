.menu {
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: auto;
  a {
    margin: 0 1.5rem;
    font-size: 1.4rem;
    transition: 0.2s color;
    &:hover {
      color: var(--subColor);
    }
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.menuSp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--mainColor);
  color: #fff;
  z-index: 10;
  padding: 100px 0 0 0;
}
.menuList {
  display: flex;
  height: 100%;
  z-index: 99;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  gap: 20px;
  font-size: 16px;
  padding: 0 1.5rem;
  li {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.4);
    padding-bottom: 20px;
    display: block;
    width: 100%;
  }
  li:last-child {
    border-bottom: none;
    text-align: center;
    margin-top: 4rem;
  }
}
