.section {
  margin: 6rem 0 8rem;
  @media screen and (max-width: 640px) {
    margin: 6rem 0;
  }
}
.description {
  line-height: 1.8;
  width: 70rem;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.trouble {
  margin-bottom: 8rem;
  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
  }
}

.troubleList {
  display: flex;
  gap: 1.5rem;
  @media screen and (max-width: 640px) {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.troubleItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  justify-content: space-around;
  width: 33.3%;
  text-align: center;
  @media screen and (max-width: 640px) {
    width: 100%;
    &:nth-child(n + 2) {
      margin-top: 4rem;
    }
  }
  p {
    font-weight: bold;
    font-size: 1.6rem;
    padding: 0 1rem;
  }
}

.troubleItem::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 55%;
  bottom: -2rem;
  z-index: -1;
  background: #e1f5fe;
}

.troubleText {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.8;
  text-align: center;
  margin: 6rem 0 4rem;
  @media screen and (max-width: 640px) {
    font-size: 1.6rem;
  }
}

.space {
  background: var(--mainColor);
  padding: 14rem 0 6rem;
  color: #fff;
  text-align: center;
  font-size: 4.2rem;
  z-index: -2;
  position: relative;
  margin-top: -8rem;
  font-weight: bold;
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-top: 50px solid var(--mainColor);
    position: absolute;
    bottom: -5rem;
    left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (max-width: 1024px) {
    font-size: 3.6rem;
    &::after {
      border-top: 40px solid var(--mainColor);
      bottom: -4rem;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 2.8rem;
    padding: 12rem 0 4rem;
    &::after {
      bottom: -3rem;
    }
  }
}
.wave {
  position: relative;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    height: 4px;
    border-radius: 9999px;
    background: linear-gradient(134deg, #c0bb40 0%, #9ad916 100%);
  }
}
