.ctaArea {
  background: linear-gradient(180deg, #77d5ff 0%, #00aaf6 100%);
  color: #fff;
  padding: 4rem 8rem;
  position: relative;
  max-width: 100rem;
  margin: 0 auto;
  @media screen and (max-width: 640px) {
    padding: 4rem 2rem;
  }
}
.ctaArea::after {
  content: "";
  position: absolute;
  right: 5rem;
  top: -8rem;
  display: block;
  background: url("../images/ctaillust.svg");
  background-size: contain;
  width: 8rem;
  height: 15rem;
  background-repeat: no-repeat;
  @media screen and (max-width: 640px) {
    right: 0;
    width: 5rem;
    top: -6rem;
  }
}

.ctaAreaHeading {
  line-height: 1.8;
  font-size: 2.6rem;
  font-weight: bold;
  margin: 3rem 0;
  @media screen and (max-width: 640px) {
    font-size: 2.2rem;
  }
}

.btn {
  font-size: 2rem;
  padding: 1.5rem 0;
  border: 1px solid transparent;
  @media screen and (max-width: 640px) {
    font-size: 1.6rem;
    width: 100%;
    display: block;
  }
}
