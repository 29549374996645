.mainVisual {
  background: url("../images/mv-bg.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 10rem;
}

.inner {
  width: 1150px;
  margin: 0 auto;
  max-width: 93%;
}

.contents {
  display: flex;
  color: #fff;
  gap: 4rem;
  justify-content: center;
}

.mvTitle {
  font-size: 3.8rem;
  color: #fff;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.price {
  display: inline-block;
  margin: 0 0.8rem;
}

.textNumber {
  font-size: 2em;
}

.mvText {
  font-size: 1.8rem;
  color: #fff;
  line-height: 1.8;
}

.button {
  margin: 2rem 0;
}

@media screen and (max-width: 1024px) {
  .mvTitle {
    font-size: 3.4rem;
  }
  .mvText {
    font-size: 1.6rem;
  }
  .contents {
    justify-content: start;
  }
  .contents p {
    height: 10rem;
  }
  .contents img {
    height: 100%;
    width: auto;
  }
}
@media screen and (max-width: 640px) {
  .mainVisual {
    background: url("../images//mv-bg_sp.png");
    padding-top: 8rem;
    background-size: cover;
  }
  .mvTitle {
    font-size: 2.4rem;
  }
  .contents p {
    height: 100px;
  }
  .contents p img {
    height: auto;
    width: auto;
  }
}
