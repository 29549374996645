#swiper {
  .swiper-button-next,
  .swiper-button-prev {
    font-size: 1.5rem;
    background-color: var(--mainColor);
    border-radius: 999px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: background-color 0.3s ease;
    &::after {
      content: "";
      width: 12px;
      height: 12px;
    }
  }
  .swiper-button-next {
    &::after {
      transform: translateX(-3px) rotate(45deg);
      border-top: 2px solid #fff; /* Arrow color */
      border-right: 2px solid #fff; /* Arrow color */
    }
  }
  .swiper-button-prev {
    &::after {
      transform: translateX(3px) rotate(45deg);
      border-bottom: 2px solid #fff; /* Arrow color */
      border-left: 2px solid #fff; /* Arrow color */
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    cursor: not-allowed;
  }
}
