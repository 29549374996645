@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Noto+Sans+JP:wght@400;500;700&display=swap');


:root {
  --mainColor: #03A9F4;
  --subColor: #f47703;
  --baseColor: #333;
}

html {
  font-size: 10px;
  font-size: calc(10 / 1400 * 100vw);
}

body {
  margin: 0;
  font-family: 'Noto sans JP', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: .05em;
  line-height: 1.5;
  color: var(--baseColor);
}

img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

@media screen and (min-width: 1401px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: calc(10 / 1024 * 100vw);
  }

  body {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 640px) {
  html {
    font-size: calc(10 / 375 * 100vw);
  }

  body {
    font-size: 1.4rem;
  }
}

.inner {
  width: 1200px;
  margin: 0 auto;
  max-width: 90%;
}

.text-number {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.btn {
  border-radius: 9999px;
  background: linear-gradient(134deg, #C0BB40 0%, #9AD916 100%);
  padding: 1rem 4rem;
  color: #fff;
  border: 1px solid transparent;
  transition: all ease .35s;
  font-weight: bold;
}

@media(hover :hover) {
  .btn:hover {
    background: #fff;
    color: #C0BB40;
    border: 1px solid #C0BB40;
  }
}