.section {
  margin-top: 8rem;
  padding: 8rem 0;
  background: #f9f9f9;
  @media screen and (max-width: 640px) {
    margin-top: 6rem;
    padding: 4rem 0;
  }
}

.faqInner {
  width: 100rem;
  margin: 0 auto;
  max-width: 90%;
  @media screen and (max-width: 640px) {
    max-width: 100%;
  }
}
